import React from 'react';
// import { Link, useStaticQuery, graphql } from 'gatsby';
import { Logo, Bletter } from '../components/icons';


const Header = () => {

  // const data = useStaticQuery(graphql`
  //   query {
  //     allWpProject(sort: { fields: date, order: DESC }) {
  //       edges {
  //         node {
  //           title
  //           uri
  //           slug
  //           id
  //           acf {
  //             location
  //             featureImage {
  //               altText
  //               localFile {
  //                 childImageSharp {
  //                   gatsbyImageData(width: 1200)
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //     allWpStory(sort: { fields: title, order: ASC }) {
  //       edges {
  //         node {
  //           title
  //           uri
  //           slug
  //           id
  //           acf {
  //             location
  //             featureImage {
  //               altText
  //               localFile {
  //                 childImageSharp {
  //                   gatsbyImageData(width: 1200)
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // `);

    // Clear hoveredImage when navigating away from the section
    // useEffect(() => {
    //   setHoveredImage(null);
    // }, [activeLink]);

  return (
    <>
      <header className='header'>
        <div className='header__inner'>
          <div className='header__col'>
            <div className="b-letter">
              <Bletter color='#312012' />
            </div>
          </div>
          <div className='header__col'>
            <div className='logo'>
              <Logo color='#312012' />
            </div>
          </div>
        </div>
      </header>

      {/* <div className={`off-canvas ${offCanvas && 'off-canvas--active'}`} style={{ backgroundColor: showProjectsMenu ? '#BFA487' : (showStoriesMenu ? '#AA9D92' : '#EEE9E2') }}>
        <div className='off-canvas__inner'>
          <nav className='off-canvas__nav'>
            <ul>
              <li></li>
            </ul>
          </nav>
        </div>
      </div> */}
    </>
  );
};

export default Header;
